import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const CustomLink: React.FC<{ to: string; label: string, state?: any, target?: string; }> = (props) => {
  const { to, label, state, target  } = props;

  return (
    <Link
      to={to}
      state={state}
      style={{ textDecoration: 'none' }}
      target={target} 
    >

      <Typography variant="body1" color={'primary.main'}> {label}</Typography>
    </Link>
  )
}