import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { ICategory } from "./category.types";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_CATEGORY_LIST_PROGRESS = "FETCH_CATEGORY_LIST_PROGRESS";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILED = "FETCH_CATEGORY_LIST_FAILED";

export const fetchCategoryListProgress = () =>
  action(FETCH_CATEGORY_LIST_PROGRESS);
export const fetchCategoryListSuccess = (
  data: ICategory[],
  totalRecords: number
) => action(FETCH_CATEGORY_LIST_SUCCESS, { data, totalRecords });
export const fetchCategoryListFailed = () => action(FETCH_CATEGORY_LIST_FAILED);

export const fetchCategoryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCategoryListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(`/category/get-categories${searchQuery}`);
      const data: ICategory[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchCategoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCategoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertCategoryAsync =
  (
    data: Partial<ICategory>,
    file: File | null,
    onCallback: (isSuccess: boolean, data?: ICategory) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      console.log(rest);

      const asPayload = {
        category_name: data.category_name as string,
      };

      const path = await uploadFile(
        file,
        "CATEGORY",
        data.attachment || "",
        asPayload
      );

      if (path) {
        rest.attachment = path;
      }

      const res = await api.post("/category/upsert-categories", rest);
      let message = "Category saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const clearCategory = () => action(CLEAR_CATEGORY);
export const CLEAR_CATEGORY_STATE = "CLEAR_CATEGORY_STATE";
export const clearCategoryState = () => action(CLEAR_CATEGORY_STATE);
