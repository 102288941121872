import { LoadState } from "../../constants/enums";
import { ICategory, ICategoryState } from "./category.types";

export const initialCategory: ICategory = {
  categories_id: null,
  attachment: null,
  category_name: "",
  category_description: "",
  is_default_category: false,
  status: "ACTIVE",
  category_slug: "",
};

export const initialCategoryState: ICategoryState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  singleCategory: {
    data: initialCategory,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
