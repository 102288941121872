import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { ISiteConfig } from "./siteConfig.types";
import { uploadFile } from "../../helpers/uploadFile";

export const upsertSiteConfigAsync =
  (
    data: ISiteConfig,
    logoFile: File | null,
    faviconFile: File | null,
    onCallback: (isSuccess: boolean, data?: ISiteConfig) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        rowId,
        preview_fav_icon,
        preview_logo,
        ...rest
      } = data;

      const asPayload = {
        category_name: data.company_name as string,
      };

      const logoPath = await uploadFile(
        logoFile,
        "CATEGORY",
        data.logo || "",
        asPayload
      );

      const faviconPath = await uploadFile(
        faviconFile,
        "CATEGORY",
        data.fav_icon || "",
        asPayload
      );

      const res = await api.post("/configuration/upsert-company-information", {
        ...rest,
        logo: logoPath,
        fav_icon: faviconPath,
      });
      let message = "Site config saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_SITE_CONFIG_PROGRESS = "FETCH_SITE_CONFIG_PROGRESS";
export const FETCH_SITE_CONFIG_SUCCESS = "FETCH_SITE_CONFIG_SUCCESS";
export const FETCH_SITE_CONFIG_FAILED = "FETCH_SITE_CONFIG_FAILED";

export const fetchSiteConfigProgress = () => action(FETCH_SITE_CONFIG_PROGRESS);
export const fetchSiteConfigSuccess = (data: ISiteConfig) =>
  action(FETCH_SITE_CONFIG_SUCCESS, { data });
export const fetchSiteConfigFailed = (errorMessage: string) =>
  action(FETCH_SITE_CONFIG_FAILED, { errorMessage });

export const fetchSiteConfigDataAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSiteConfigProgress());
      const res = await api.get(`/configuration/get-company-information`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSiteConfigSuccess(data[data.length - 1]));
      } else {
        dispatch(
          fetchSiteConfigFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchSiteConfigFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_SITE_CONFIG = "CLEAR_SITE_CONFIG";
export const clearSiteConfig = () => action(CLEAR_SITE_CONFIG);
export const CLEAR_SITE_CONFIG_STATE = "CLEAR_SITE_CONFIG_STATE";
export const clearSiteConfigState = () => action(CLEAR_SITE_CONFIG_STATE);
