import { LoadState } from "../../constants/enums";
import { ISiteConfig, ISiteConfigState } from "./siteConfig.types";

export const defaultSiteConfig: ISiteConfig = {
  company_uuid: null,
  company_name: "",
  company_title: "",
  company_description: "",
  adsense_header_code: "",
  address: "",
  unit_or_suite: "",
  city: "",
  province_or_state: "",
  postal_code: "",
  country: "",
  phone: "",
  telephone: "",
  fax: "",
  default_language: "",
  email: "",
  accounts_email: "",
  cl_email: "",
  pl_email: "",
  default_tax_region: "",
  pst_or_gst_or_vat_number: "",
  bahamas_premium_tax: "",
  logo: "",
  fav_icon: "",
  about: "",
  status: "ACTIVE",
  created_by_name: "",
  preview_logo: "",
  preview_fav_icon: "",
};

export const defaultSiteConfigState: ISiteConfigState = {
  configData: {
    data: defaultSiteConfig,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
