import produce from "immer";
import { SiteConfigAction } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultSiteConfig, defaultSiteConfigState } from "./defaultState";
import { CLEAR_SITE_CONFIG, CLEAR_SITE_CONFIG_STATE, FETCH_SITE_CONFIG_FAILED, FETCH_SITE_CONFIG_PROGRESS, FETCH_SITE_CONFIG_SUCCESS } from "./siteConfigActions";



export const siteConfigReducer = (
  state: IStoreState["siteConfig"] = defaultSiteConfigState,
  action: SiteConfigAction,
) => {
  switch (action.type) {
    case FETCH_SITE_CONFIG_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.configData.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SITE_CONFIG_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.configData.loading = LoadState.Loaded;
        draftState.configData.data = data;
      });
      return newState;
    }
    case FETCH_SITE_CONFIG_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.configData.loading = LoadState.InProgress;
      });
      return newState;
    }
    case CLEAR_SITE_CONFIG: {
      const newState = produce(state, (draftState) => {
        draftState.configData.loading = LoadState.NotLoaded;
        draftState.configData.data = defaultSiteConfig;
      });
      return newState;
    }
    case CLEAR_SITE_CONFIG_STATE: {
      return defaultSiteConfigState;
    }
    default: {
      return state;
    }
  }
};
