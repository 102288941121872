import { LoadState } from "../../constants/enums";
import { IPlaylist, IPlaylistState } from "./playlist.types";

export const initialPlaylist: IPlaylist = {
  videos_final_data_id:null,
    artist_id: "",
    artist_name: "",
    artist_slug: "",
    video_title: "",
    video_description:  null,
    video_link: "",
    video_tags: [],
    categories: [],
    video_small_img:  null,
    video_medium_img:  null,
    video_large_img:  null,
    video_standard_img:  null,
    video_maxres_img:  null,
    video_slug: "",
    video_publish_date: "",
    video_lyrics:  null,
    original_credits:  null,
    video_duration: "",
    video_definition:  null,
    video_caption:  null,
    view_page_count: 0,
    status: "ACTIVE",
}

export const initialPlaylistState: IPlaylistState = {
    list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error:  null,
      }, 
      singlePlaylist: {
        data: initialPlaylist,
        loading: LoadState.NotLoaded,
        error:  null,
      }
}