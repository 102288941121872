import React from "react";
import { ISecurityGroup } from "../../redux/security/security.types";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { api } from "../../api/api";
import { useDispatchWrapper } from "../../hooks";
import { showMessage } from "../../redux/messages/messagesActions";
import { LoadState } from "../../constants/enums";
import { LinearProgress } from "@mui/material";

interface IAppConfigContext {
  companyName: string;
  logo: string;
  favIcon: string;
  companyTitle?: string;
  companyDescription?: string;
  adsHeaderCode?: string;
}

export const AppConfigContext = React.createContext<IAppConfigContext>({
  companyName: "",
  logo: "",
  favIcon: "",
  companyTitle: "",
  companyDescription: "",
  adsHeaderCode: "",
});

export const useAppConfig = () => React.useContext(AppConfigContext);

export const AppConfigProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [companyName, setCompanyName] = React.useState<string>("");
  const [logo, setLogo] = React.useState<string>("");
  const [favIcon, setFavIcon] = React.useState<string>("");
  const [loading, setLoading] = React.useState<LoadState>(LoadState.NotLoaded);
  const { user } = useAuth();
  const dispatch = useDispatchWrapper();

  const fetchAppConfig = async () => {
    const localConfig = localStorage.getItem("config");

    if (localConfig) {
      const { companyName, logo, favIcon } = JSON.parse(localConfig);
      console.log("#logo1", logo);
      setCompanyName(companyName);
      setLogo(logo);
      setFavIcon(favIcon);
      setLoading(LoadState.Loaded);
    }
    try {
      setLoading(LoadState.InProgress);
      const response = await api.get(
        "/configuration/get-public-company-information"
      );

      const companyData = response.data.data[0];

      console.log(companyData);

      const configData = {
        companyName: companyData.company_name,
        logo: companyData.preview_logo,
        favIcon: companyData.preview_fav_icon,
      };

      setCompanyName(configData.companyName);
      setLogo(configData.logo);
      setFavIcon(configData.favIcon);

      localStorage.setItem("config", JSON.stringify(configData));
      setLoading(LoadState.Loaded);
    } catch (err: any) {
      setLoading(LoadState.Failed);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  React.useEffect(() => {
    fetchAppConfig();
  }, []);

  // if (!user.isLogin) {
  //   return <>{props.children}</>;
  // }

  if (loading !== LoadState.Loaded) {
    return <LinearProgress />;
  }

  return (
    <AppConfigContext.Provider
      value={{
        companyName,
        logo,
        favIcon,
      }}>
      {props.children}
    </AppConfigContext.Provider>
  );
};
