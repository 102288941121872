import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  initialSitePageContentList,
  initialSitePageContentState,
} from "./defaultState";
import { SitePageContentActions } from ".";
import {
  CLEAR_SITE_PAGE_CONTENT,
  CLEAR_SITE_PAGE_CONTENT_STATE,
  FETCH_SITE_PAGE_CONTENT_FAILED,
  FETCH_SITE_PAGE_CONTENT_LIST_FAILED,
  FETCH_SITE_PAGE_CONTENT_LIST_PROGRESS,
  FETCH_SITE_PAGE_CONTENT_LIST_SUCCESS,
  FETCH_SITE_PAGE_CONTENT_PROGRESS,
  FETCH_SITE_PAGE_CONTENT_SUCCESS,
} from "./sitePageContentActions";

export const sitePageContentReducer = (
  state: IStoreState["sitePageContent"] = initialSitePageContentState,
  action: SitePageContentActions
) => {
  switch (action.type) {
    case FETCH_SITE_PAGE_CONTENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SITE_PAGE_CONTENT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_SITE_PAGE_CONTENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_SITE_PAGE_CONTENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SITE_PAGE_CONTENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleSitePageContent.loading = LoadState.Loaded;
        draftState.singleSitePageContent.data = data;
      });
      return newState;
    }
    case FETCH_SITE_PAGE_CONTENT_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.singleSitePageContent.loading = LoadState.InProgress;
      });
      return newState;
    }

    case CLEAR_SITE_PAGE_CONTENT: {
      const newState = produce(state, (draftState) => {
        draftState.singleSitePageContent.loading = LoadState.NotLoaded;
        draftState.singleSitePageContent.data = initialSitePageContentList;
      });
      return newState;
    }

    case CLEAR_SITE_PAGE_CONTENT_STATE: {
      return initialSitePageContentState;
    }
    default: {
      return state;
    }
  }
};
