import { LoadState } from "../../constants/enums";
import { IBranch, IDataManagementState, IZone } from "./dataManagement.types";

export const defaultUserBranch: IBranch = {
    branch_name: "",
    branch_uuid: null,
    status: "ACTIVE"
}
export const defaultUserZone: IZone = {
    zone_name: " ",
    branches_uuid: [
        {
            branch_name: "",
            branch_uuid: ""
        }
    ],
    zone_uuid: null,
    status: "ACTIVE"
}

export const defaultDataManagementState: IDataManagementState = {
    branchList: {
        list: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    branch: {
        data: defaultUserBranch,
        loading: LoadState.NotLoaded,
        error: null,
    },
    zoneList: {
        list: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    zone: {
        data: defaultUserZone,
        loading: LoadState.NotLoaded,
        error: null,
    }
}