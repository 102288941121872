import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { ISitePageContent } from "./singlePageContent.types";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_SITE_PAGE_CONTENT_LIST_PROGRESS =
  "FETCH_SITE_PAGE_CONTENT_LIST_PROGRESS";
export const FETCH_SITE_PAGE_CONTENT_LIST_SUCCESS =
  "FETCH_SITE_PAGE_CONTENT_LIST_SUCCESS";
export const FETCH_SITE_PAGE_CONTENT_LIST_FAILED =
  "FETCH_SITE_PAGE_CONTENT_LIST_FAILED";

export const fetchSitePageContentListProgress = () =>
  action(FETCH_SITE_PAGE_CONTENT_LIST_PROGRESS);
export const fetchSitePageContentListSuccess = (
  data: ISitePageContent[],
  totalRecords: number
) => action(FETCH_SITE_PAGE_CONTENT_LIST_SUCCESS, { data, totalRecords });
export const fetchSitePageContentListFailed = () =>
  action(FETCH_SITE_PAGE_CONTENT_LIST_FAILED);

export const upsertSitePageContentAsync =
  (
    data: ISitePageContent,
    featuredImage: File | null | undefined,
    onCallback: (isSuccess: boolean, data?: ISitePageContent) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, ...rest } = data;

      const asPayload = {
        category_name: data.page_name as string,
      };

      const logoPath = featuredImage
        ? await uploadFile(
            featuredImage,
            "CATEGORY",
            data.featured_image || "",
            asPayload
          )
        : data.featured_image || "";
      
      const res = await api.post("/configuration/upsert-page-content", {
        ...rest,
        featured_image: logoPath,
      });
      let message = "Artist saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchSitePageContentList =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSitePageContentListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(
        `/configuration/get-page-content${searchQuery}`
      );
      const data: ISitePageContent[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchSitePageContentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSitePageContentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_SITE_PAGE_CONTENT_PROGRESS =
  "FETCH_SITE_PAGE_CONTENT_PROGRESS";
export const FETCH_SITE_PAGE_CONTENT_SUCCESS =
  "FETCH_SITE_PAGE_CONTENT_SUCCESS";
export const FETCH_SITE_PAGE_CONTENT_FAILED = "FETCH_SITE_PAGE_CONTENT_FAILED";

export const fetchSitePageContentProgress = () =>
  action(FETCH_SITE_PAGE_CONTENT_PROGRESS);
export const fetchSitePageContentSuccess = (
  data: ISitePageContent,
  totalRecords: number
) => action(FETCH_SITE_PAGE_CONTENT_SUCCESS, { data, totalRecords });
export const fetchSitePageContentFailed = () =>
  action(FETCH_SITE_PAGE_CONTENT_FAILED);

export const fetchSingleSitePageContent =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSitePageContentProgress());
      const res = await api.get(
        `/configuration/get-page-content?page_content_uuid=${uuid}&pageNo=1&itemPerPage=10`
      );
      const data: ISitePageContent = res.data.data[0];

      const totalRecords = res.data.totalRecords;

      dispatch(fetchSitePageContentSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSitePageContentFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const CLEAR_SITE_PAGE_CONTENT = "CLEAR_SITE_PAGE_CONTENT";
export const clearSitePageContent = () => action(CLEAR_SITE_PAGE_CONTENT);
export const CLEAR_SITE_PAGE_CONTENT_STATE = "CLEAR_ARTIST_STATE";
export const clearSitePageContentState = () =>
  action(CLEAR_SITE_PAGE_CONTENT_STATE);
