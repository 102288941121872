import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { IArtist, IArtistProfile, IArtistWiki } from "./artist.types";

export const FETCH_ARTIST_LIST_PROGRESS = "FETCH_ARTIST_LIST_PROGRESS";
export const FETCH_ARTIST_LIST_SUCCESS = "FETCH_ARTIST_LIST_SUCCESS";
export const FETCH_ARTIST_LIST_FAILED = "FETCH_ARTIST_LIST_FAILED";

export const fetchArtistListProgress = () => action(FETCH_ARTIST_LIST_PROGRESS);
export const fetchArtistListSuccess = (data: IArtist[], totalRecords: number) =>
  action(FETCH_ARTIST_LIST_SUCCESS, { data, totalRecords });
export const fetchArtistListFailed = () => action(FETCH_ARTIST_LIST_FAILED);

export const syncArtistAndCronAsync =
  (
    artistId: string | null,
    onCallback: (isSuccess: boolean, data?: IArtist) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // const { create_ts, insert_ts, rowId, ...rest } = data;
      const res = await api.post("/playlist/sync-artists", {
        artist_ids: artistId === null ? [] : [artistId],
        vidoes_fetch_load_logs_code: null,
        manual_trigger: 1,
        total_sequence: [1, 2, 3],
      });
      let message = res.data.message;
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchArtistListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchArtistListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(`/artist/get-artist${searchQuery}`);
      const data: IArtist[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchArtistListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchArtistListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertArtistAsync =
  (
    data: IArtist,
    onCallback: (isSuccess: boolean, data?: IArtist) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      const res = await api.post("/artist/add-artist", rest);
      let message = "Artist saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const deleteArtistAsync =
  (
    data: IArtist,
    onCallback: (isSuccess: boolean, data?: IArtist) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, artist_id } = data;
      const res = await api.delete(
        `/artist/delete-artist?artist_id=${artist_id}`
      );
      let message = "Artist Delete successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const updateArtistProfile =
  (
    data: IArtistProfile,
    onCallback: (isSuccess: boolean, data?: IArtistProfile) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      const res = await api.put("/artist/update-artist", rest);
      let message = "Artist saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ARTIST_PROFILE_PROGRESS = "FETCH_ARTIST_PROFILE_PROGRESS";
export const FETCH_ARTIST_PROFILE_SUCCESS = "FETCH_ARTIST_PROFILE_SUCCESS";
export const FETCH_ARTIST_PROFILE_FAILED = "FETCH_ARTIST_PROFILE_FAILED";

export const fetchArtistProfileProgress = () =>
  action(FETCH_ARTIST_PROFILE_PROGRESS);
export const fetchArtistProfileSuccess = (data: IArtistProfile) =>
  action(FETCH_ARTIST_PROFILE_SUCCESS, { data });
export const fetchArtistProfileFailed = (errorMessage: string) =>
  action(FETCH_ARTIST_PROFILE_FAILED, { errorMessage });

export const fetchArtistProfileAsync =
  (id: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchArtistProfileProgress());
      const res = await api.get(`/artist/get-artist?artist_id=${id}`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchArtistProfileSuccess(data[0]));
      } else {
        dispatch(
          fetchArtistProfileFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchArtistProfileFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// artist wiki

export const updateArtistWikiAsync =
  (
    data: IArtistWiki,
    onCallback: (isSuccess: boolean, data?: IArtistWiki) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;

      const res = await api.put("/artist/update-artist-wiki-bio", rest);
      let message = "Artist saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ARTIST_WIKI_PROGRESS = "FETCH_ARTIST_WIKI_PROGRESS";
export const FETCH_ARTIST_WIKI_SUCCESS = "FETCH_ARTIST_WIKI_SUCCESS";
export const FETCH_ARTIST_WIKI_FAILED = "FETCH_ARTIST_WIKI_FAILED";

export const fetchArtistWikiProgress = () => action(FETCH_ARTIST_WIKI_PROGRESS);
export const fetchArtistWikiSuccess = (data: IArtistWiki) =>
  action(FETCH_ARTIST_WIKI_SUCCESS, { data });
export const fetchArtistWikiFailed = (errorMessage: string) =>
  action(FETCH_ARTIST_WIKI_FAILED, { errorMessage });

export const fetchArtistWikiAsync =
  (id: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchArtistProfileProgress());
      const res = await api.get(`/artist/get-artist-wiki-bio?artist_id=${id}`);
      const data = res.data.data;
      if (data.length > 0) {
        const modifiedData: IArtistWiki = {
          ...data[0],
          additional_info: data[0].additional_info ?? [
            { detail_key: "", detail_value: "" },
          ],
        };

        dispatch(fetchArtistWikiSuccess(modifiedData));
      } else {
        dispatch(
          fetchArtistProfileFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchArtistProfileFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ARTIST = "CLEAR_ARTIST";
export const clearArtist = () => action(CLEAR_ARTIST);
export const CLEAR_ARTIST_WIKI = "CLEAR_ARTIST_WIKI";
export const clearArtistWiki = () => action(CLEAR_ARTIST_WIKI);
export const CLEAR_ARTIST_STATE = "CLEAR_ARTIST_STATE";
export const clearArtistState = () => action(CLEAR_ARTIST_STATE);
