import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { ICron } from "./cron.types";

export const FETCH_CRON_LIST_PROGRESS = "FETCH_CRON_LIST_PROGRESS";
export const FETCH_CRON_LIST_SUCCESS = "FETCH_CRON_LIST_SUCCESS";
export const FETCH_CRON_LIST_FAILED = "FETCH_CRON_LIST_FAILED";

export const fetchCronListProgress = () => action(FETCH_CRON_LIST_PROGRESS);
export const fetchCronListSuccess = (data: ICron[], totalRecords: number) =>
	action(FETCH_CRON_LIST_SUCCESS, { data, totalRecords });
export const fetchCronListFailed = () => action(FETCH_CRON_LIST_FAILED);

export const fetchCronListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchCronListProgress());
			const searchQuery = getSearchQuery(queryParams);
			const res = await api.get(`/cron/get-crons-list${searchQuery}`);
			const data: ICron[] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchCronListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchCronListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertCronAsync =
	(
		data: ICron,
		onCallback: (isSuccess: boolean, data?: ICron) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const { create_ts, insert_ts, rowId, ...rest } = data;
			const res = await api.post("/cron/upsert-cron", rest);
			let message = "Cron saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

// sync artist playlist
export const runCronAsync =
	(): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const res = await api.get(`/playlist/sync-all-artist-playlist`);
			let message = res.data.message;
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const CLEAR_CRON = "CLEAR_CRON";
export const clearCron = () => action(CLEAR_CRON);
export const CLEAR_CRON_STATE = "CLEAR_CRON_STATE";
export const clearCronState = () => action(CLEAR_CRON_STATE);
