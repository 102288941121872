import { Refresh } from "@mui/icons-material";
import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IRefreshPluginsProps } from "./interfaces/IRefreshPluginProps";

export const RefreshPlugin: React.FC<IRefreshPluginsProps> = (props) => {
  const { onClick } = props;
  const [rotation, setRotation] = useState(0);

  const handleClick = () => {
    setRotation((prev) => prev + 360); // Increment rotation by 360 degrees
    onClick?.();
  };

  return (
    <Box width="fit-content">
      <Tooltip title="Refresh Data">
        <Button variant="text" onClick={handleClick}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Refresh
              color="primary"
              sx={{
                fontSize: 25,
                transition: "transform 0.5s ease-in-out",
                transform: `rotate(${rotation}deg)`,
              }}
            />
            <Typography
              variant="body1"
              fontSize="1rem"
              fontWeight={600}
              color="primary.main"
            >
              Refresh
            </Typography>
          </Stack>
        </Button>
      </Tooltip>
    </Box>
  );
};
