import { LoadState } from "../../constants/enums";
import {
  ISitePageContent,
  ISitePageContentState,
} from "./singlePageContent.types";

export const initialSitePageContentList: ISitePageContent = {
  page_content_unique_id: 0,
  page_content_uuid: "",
  page_name: "",
  page_url: "",
  page_title: "",
  meta_description: "",
  page_content: "",
  featured_image: null,
  status: "",
  created_by_uuid: "",
  created_by_name: "",
  modified_by_uuid: "",
  modified_by_name: "",
};

export const initialSitePageContentState: ISitePageContentState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  singleSitePageContent: {
    data: initialSitePageContentList,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
