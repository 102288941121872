import { LoadState } from "../../constants/enums";
import { ICron, ICronState } from "./cron.types";

export const initialCron: ICron = {
    cron_id: null,
    cron_setup_unique_id: null,
    cron_setup_uuid: null,
    cron_name: "",
    cron_scheduler: {
        second: "0",
        minute: "0",
        hour: "0",
        date: "1",
        month: "1",
        week: "0",
        repeatEvery: true
    },
    status: "ACTIVE",
};

export const initialCronState: ICronState = {
    list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null
    },
    singleData: {
        data: initialCron,
        loading: LoadState.NotLoaded,
        error: null
    }
};
