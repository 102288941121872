import axios from "axios";
import { getUserAuth } from "../helpers";

export const api = axios.create({
  baseURL: process.env["REACT_APP_SERVER_URL"],
});

const logoutUser = () => {
  // Perform any necessary cleanup tasks, e.g., clearing local storage
  localStorage.clear();
  // Redirect to the login page or perform any other required action
  window.alert(
    "Attention: Your session has expired. Please log in again to continue accessing the system. Thank you!"
  );
  window.location.href = "/auth/login"; // Replace with your desired logout destination
};

api.interceptors.request.use((config) => {
  const accessToken = getUserAuth();
  const siteSecretKey = process.env.REACT_APP_SITE_SECERT_KEY; // Read from environment variable

  console.log(accessToken, "token ======>");
  console.log(siteSecretKey, "site secret key ======>");

  if (config.headers) {
    // Add auth token if available
    if (accessToken) {
      config.headers["auth-Token"] = `${accessToken}`;
    }

    // Add site secret key to headers
    if (siteSecretKey) {
      config.headers["key"] = siteSecretKey;
    }
  }
  return config;
});



api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      logoutUser();
      // const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');
      // const currentTime = new Date().getTime() / 1000; // Convert to seconds

      // if (currentTime > accessTokenExpiresAt) {
      //   // Access token has expired, trigger logout
      //   logoutUser();
      // }
    }

    return Promise.reject(error);
  }
);
